<template>
  <div class="height1">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item label="药品分类">
                <el-select v-model="searchForm.categoryId" placeholder="请选择" size="small" style="width: 120px"
                  @change="Search">
                  <el-option :label="item.categoryName" :value="item.categoryId" v-for="(item, index) in categoryData"
                    :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="是否含空库存">
                <el-radio-group v-model="searchForm.isEmpty" @change="Search()">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions0" size="small" style="margin-right: 10px; width: 260px" clearable
                  @change="Search">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="批号">
                <el-input v-model="searchForm.batchNumber" type="text" size="small" placeholder="批号"
                  @keyup.enter.native="Search" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="药品名称/别名/商品名称"
                  @keyup.enter.native="Search" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
                <el-button type="primary" size="small" @click="addDrug()" plain>药品入库</el-button>
                <el-button type="warning" size="mini" class="el-icon-download" @click="downloadTemplate">下载模板</el-button>
              </el-form-item>
              <el-form-item>
                <el-upload class="upload" :show-file-list="false" accept=".xlsx,.xls" :auto-upload="true" :headers="headers" :action="fileUploadUrl" :on-success="uploadSuccess">
                  <el-button size="mini" type="success" class="el-icon-upload2">批量导入</el-button>
                </el-upload>
              </el-form-item>
              <!-- <el-form-item>
                <el-upload class="upload" action="#" :show-file-list="false" :on-change="handleExcel"
                  accept="'.xlsx','.xls'" :auto-upload="false" :headers="headers">
                  <el-button size="mini" type="primary" class="el-icon-upload2">导入</el-button>
                </el-upload>
              </el-form-item> -->
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }"
          height="calc(100% - 80px)">
          <el-table-column type="index" label="编号" width="50"></el-table-column>
          <el-table-column prop="mzDrugOrg.mzDrug.drugName" label="药品名称">
            <template slot-scope="scope"> {{ scope.row.mzDrugOrg.mzDrug.drugName }}
              <span v-if="scope.row.mzDrugOrg.mzDrug.drugType=='T'">(统)</span>
              <span v-if="scope.row.mzDrugOrg.mzDrug.drugType=='X'">(选)</span>
              <span v-if="scope.row.mzDrugOrg.mzDrug.drugType=='J'">(精选)</span>
            </template>
          </el-table-column>
          <el-table-column prop="mzDrugOrg.mzDrug.drugAlias" label="别名" align="center"> </el-table-column>
          <el-table-column prop="mzDrugOrg.productName" label="商品名" align="center"> </el-table-column>
          <el-table-column prop="mzDrugOrg.mzDrug.category.categoryName" label="药品分类" align="center"> </el-table-column>
          <el-table-column prop="mzDrugOrg.specification" label="规格" align="center">
            <template slot-scope="scope">
              <p v-if="scope.row.mzDrugOrg.specification">
                {{ scope.row.mzDrugOrg.specification }}/{{ scope.row.mzDrugOrg.specificationUnit }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="mzDrugOrg.productFactory" label="生产厂家" show-overflow-tooltip
            align="center"></el-table-column>
          <!-- <el-table-column prop="mzDrugOrg.supplier" label="供应商" align="center"></el-table-column> -->
          <el-table-column prop="batchNumber" label="批号" align="center"></el-table-column>
          <el-table-column prop="putInPrice" label="进货价(元)" align="center">
            <template slot-scope="scope">
              {{ scope.row.putInPrice.toFixed(2) }}/{{ scope.row.mzDrugOrg.specificationUnit }}
            </template>
          </el-table-column>
          <el-table-column prop="tradingPrice" label="售价(元)" align="center">
            <template slot-scope="scope">
              {{ scope.row.tradingPrice.toFixed(2)
              }}/{{ scope.row.mzDrugOrg.isSeparate ? scope.row.mzDrugOrg.separateNumberUnit : scope.row.mzDrugOrg.specificationUnit
}}
            </template>
          </el-table-column>
          <el-table-column prop="putInNumber" label="入库数量" align="center">
            <template slot-scope="scope">
              {{ scope.row.putInNumber
              }}（{{ scope.row.mzDrugOrg.isSeparate ? scope.row.mzDrugOrg.separateNumberUnit : scope.row.mzDrugOrg.specificationUnit
}}）
            </template>
          </el-table-column>
          <el-table-column prop="inventoryNumber" label="剩余库存" align="center">
            <template slot-scope="scope">
              {{ scope.row.inventoryNumber
              }}（{{ scope.row.mzDrugOrg.isSeparate ? scope.row.mzDrugOrg.separateNumberUnit : scope.row.mzDrugOrg.specificationUnit
}}）
            </template>
          </el-table-column>
          <el-table-column prop="addTime" label="添加时间" align="center" width="150px" sortable>
            <template slot-scope="scope">
              <span>{{ scope.row.addTime | timefilters }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="validDate" label="药品有效期" align="center" width="190px" sortable>
            <template slot-scope="scope">
              <span v-if="scope.row.productionDate">{{ scope.row.productionDate.split('T')[0] }} 至 </span>
              <span>{{ scope.row.validDate.split('T')[0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <span class="danger optionBtn" @click="returnBtn(scope.row)" v-if="scope.row.inventoryNumber>0">退库</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal"
          layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
    <el-dialog title="退库" :visible.sync="returnDialog" width="30%">
      <el-form class="dialog-form" :model="returnForm" ref="returnForm" :label-width="formLabelWidth" :rules="rules">
        <el-form-item label="退库数量" prop="putOutNumber">
          <el-input-number v-model="returnForm.putOutNumber" size="small" controls-position="right" :precision="0"
            :step="1" :max="putOutNumber" :min="1" class="leftInput" style="width: 150px;"></el-input-number>
        </el-form-item>
        <el-form-item label="退库原因" prop="putOut">
          <el-select v-model="returnForm.putOutReason" placeholder="请选择" clearable style="width:100%">
            <el-option :label="item.adviceContent" :value="item.adviceContent" v-for="item in returnReason"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="CloseDialog('returnForm')">取 消</el-button>
        <el-button type="primary" @click="saveData('returnForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Outpatient } from "@/components/DrugDomain/Outpatient"
export default {
  inject: ['reload'],
  computed: {
    headers() {
      return {
        Authorization: 'Bearer' + ' ' + JSON.parse(sessionStorage.getItem(this.ClientId + 'Token')).access_token,
      }
    },
  },
  data () {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    return {
      OutpatientDomain: outpatient,
      // headers: { "Content-Type": "multipart/form-data;charset=UTF-8" },
      fileUploadUrl: `${this.Services.Drug}/Upload`,
      tableData: [],
      categoryData: [],
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      searchForm: {
        keyWord: '',
        batchNumber: '',
        categoryId: 0,
        isEmpty: true,
        datepicker: ''
      },
      pickerOptions0: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        },
      },
      title: "余额明细",
      DataCount: 0,
      json_fields: {
        订单编号: {
          filed: "key",
          callback: val => {
            return "NO：" + val.key
          },
        },
        当前余额: "name",
      },
      fileList: [],
      returnDialog: false,
      formLabelWidth: '90px',
      returnForm: {
        mzDrugInId: '',
        putOutNumber: null,
        putOutReason: ""
      },
      returnReason: [],
      putOutNumber: 0,
      rules: {
        putOutNumber: [{ required: true, message: '请输入退库数量', trigger: 'blur' }],
        putOutReason: [{ required: true, message: "请选择退库原因", trigger: "change" }],
      },
    }
  },
  mounted () {
    this.getList()
    this.LoadCategory()
    this.getReturnReason()
  },
  methods: {
    downloadTemplate() {
      let a = document.createElement('a')
      a.href = './OrgDrugWarehousingImp.xlsx'
      a.download = '药品入库模版.xlsx'
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    uploadSuccess(res, file) {
      var _this = this
      let filePath = `${res[0].completeFilePath}`.trim()
      this.OutpatientDomain.ImportMZDrugOrgIn(
        filePath,
        function (data) {
          _this.$alert(data.data, '提示', {
            confirmButtonText: '确定',
            callback: (action) => {
              _this.pageIndex = 1
              _this.getList()
            },
          })
        },
        function (err) {
          let jsonObj = JSON.parse(err.msg)
          // _this.$message({
          //   message: jsonObj.msg,
          //   type: 'error',
          // })
          _this.$alert(jsonObj.msg, '提示', {
            confirmButtonText: '确定',
            callback: (action) => {},
          })
        }
      )
    },
    getReturnReason () {
      var _this = this
      _this.OutpatientDomain.RefundRepertoryList('', 1,
        function (data) {
          _this.returnReason = data.data.results
        },
        function (err) {
          console.log(err)
        }
      )
    },
    saveData (formName) {
      var _this = this
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(_this.returnForm)
          const loading = this.$loading({
            lock: true,
            background: 'rgba(0, 0, 0, 0.5)'
          })
          _this.OutpatientDomain.DrugOrgOut(_this.returnForm,
            function (data) {
              loading.close()
              _this.$message({
                message: '操作成功',
                type: 'success',
              })
              _this.reload()

            },
            function (error) {
              loading.close()
              let jsonObj = JSON.parse(error.msg)
              _this.$message({
                message: jsonObj.msg,
                type: 'error',
              })
              console.log(error)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    CloseDialog (formName) {
      this.returnDialog = false
      this.$refs[formName].resetFields()
    },
    returnBtn (row) {
      this.returnDialog = true
      this.putOutNumber = row.inventoryNumber
      this.returnForm = {
        mzDrugInId: row.id,
        putOutNumber: row.inventoryNumber,
        putOutReason: ""
      }
    },
    LoadCategory () {
      var _this = this
      _this.OutpatientDomain.GetOutpatientCategories(
        function (data) {
          _this.categoryData = data.data.categories
          _this.categoryData.unshift({ categoryId: 0, categoryName: '全部' })
        },
        function (err) {
          console.log(err)
        }
      )
    },
    addDrug () {
      this.$router.push("./DrugsWarehousing")
    },
    changePage (pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    },
    getList () {
      var _this = this
      _this.tableData = []
      var item = _this.searchForm
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + " 00:00:00"
        item.endTime = _this.searchForm.datepicker[1] + " 23:59:59"
      } else {
        item.startTime = ""
        item.endTime = ""
      }
      _this.OutpatientDomain.DrugOrgInList(item.keyWord, item.batchNumber, item.categoryId, item.isEmpty, item.startTime, item.endTime, this.pageIndex,
        function (data) {
          _this.tableData = data.data.results
          _this.pageIndex = data.data.pageIndex
          _this.pageSize = data.data.pageSize
          _this.dataTotal = data.data.dataTotal
        },
        function (err) {
          console.log(err)
        }
      )
    },
    Search (event) {
      this.pageIndex = 1
      this.getList()
    },
    // 导入数据
    handleExcel (file, fileList) {
      console.log(file.raw)
      let formData = new FormData() //声明一个FormDate对象
      formData.append("formFile", file.raw) //把文件信息放入对象中

    },
    async fetchData () {
      var _this = this
      return await _this.getAllData()
    },
    getAllData () {
      var _this = this
      return new Promise((resolve, reject) => {
        // var item = _this.searchForm;
        // if (_this.searchForm.datepicker) {
        //   item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
        //   item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
        // } else {
        //   item.startTime = "";
        //   item.endTime = "";
        // }
        // _this.BalanceDomain.BalanceDetail(
        //   item.startTime,
        //   item.endTime,
        //   item.keyWord,
        //   item.state,
        //   1,
        //   _this.DataCount,
        //   function (data) {
        //     resolve(data.data.results);
        //   },
        //   function (err) {
        resolve("")
        //     console.log(err);
        //   }
        // );
      })
    },
  },
}
</script>

<style scoped>.dialog-form {
  width: 85%;
}</style>
